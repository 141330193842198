<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>Blockwell Name System</span>
        </portal>

        <div class="row align-items-center bwns-filter">
            <div class="col-md-6">
                <button class="btn btn-primary add-bwns" @click="showAdd = true">Add Name</button>
            </div>
        </div>
        <div class="table-responsive">
            <table id="bwns-table" class="table table-sm">
                <thead>
                <tr>
                    <th>Namespace</th>
                    <th>Name</th>
                    <th>Link</th>
                    <th>Address</th>
                    <th>Network</th>
                    <th>Title</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="ns in list">
                    <td>
                        {{ ns.namespace }}
                    </td>
                    <td>
                        {{ ns.name }}
                    </td>
                    <td>
                        <a v-if="ns.fullLink" :href="ns.fullLink" target="_blank">{{ ns.link }}</a>
                    </td>
                    <td class="bwns-address">
                        <Clipboard v-if="ns.address" :address="ns.address" :network="ns.network"/>
                    </td>
                    <td>{{ ns.networkName }}</td>
                    <td>{{ ns.title }}</td>
                </tr>

                </tbody>
            </table>
        </div>

        <Modal :show.sync="showAdd">
            <AddBwns @added="refresh" />
        </Modal>
    </layout-heading>
</template>

<script>

import 'bootstrap';
import Clipboard from "@/components/Clipboard";
import axios from "axios";
import * as util from '@/assets/lib/util';
import {paramCase} from 'change-case';
import Api from '@/lib/api/WalletApi';
import Bwns from '@/lib/api/Bwns';
import {getNetwork} from '@/assets/lib/networks';
import Modal from "@/components/Modal.vue";
import AddBwns from "@/components/AddBwns.vue";

const api = new Api('legacy');
const bwns = new Bwns();

let data = [];
export default {
    components: { AddBwns, Modal, Clipboard},
    data() {
        return {
            list: [],
            originalList: [],
            filterValue: null,
            showAdd: false
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.showAdd = false;
            bwns.listAll()
                .then(list => {
                    this.originalList = list;

                    let subdomains = ['vote', 'daico', 'lotto', 'book'];
                    this.originalList.forEach(it => {
                        if (it.network) {
                            let net = getNetwork(it.network);
                            it.networkName = net.networkName;
                        }

                        if (it.link) {
                            it.fullLink = it.link;
                        } else if (subdomains.includes(it.namespace)) {
                            it.link = `${it.namespace}.blockwell.ai/${it.name}`;
                            it.fullLink = `https://${it.link}`;
                        }
                    });
                    this.originalList.sort((a, b) => (a.namespace > b.namespace) ? 1 : -1);

                    this.list = this.originalList;
                })
                .catch(console.error);
        },
    }
}
</script>
