import { getNetwork } from "@/assets/lib/networks";
import { errorText } from "@/assets/lib/util";
import ProgressCircle from "@/components/ProgressCircle.vue";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import { mapGetters, mapState } from "vuex";
import Bwns from "@/lib/api/Bwns";
import { paramCase } from "change-case";
const bwns = new Bwns();
export default {
    name: "AddBwns",
    components: { ProgressCircle, ContractLoader },
    data() {
        return {
            addingLoading: false,
            adding: {
                address: "",
                network: null,
                name: "",
                namespace: "",
                title: ""
            },
            addingError: null
        };
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('user', ['api']),
        networkName() {
            if (this.adding.network) {
                return getNetwork(this.adding.network).networkName;
            }
            return "";
        }
    },
    methods: {
        loadContract(contract) {
            this.addingError = null;
            this.adding.address = contract.address;
            this.adding.name = paramCase(contract.name);
            this.adding.network = contract.network;
            if (contract.features.includes("book")) {
                this.adding.namespace = "book";
            }
        },
        submitToken() {
            this.addingLoading = true;
            let add = this.adding;
            bwns.create(add.name, add.namespace, add.address, add.network, add.name)
                .then(() => {
                this.adding.network = null;
                this.$emit('added');
            })
                .catch(err => {
                console.error(err);
                this.addingError = 'There was an error adding the token: ' + errorText(err);
            })
                .finally(() => this.addingLoading = false);
        }
    }
};
